import Vue from "vue";
import Router from "vue-router";
import localForage from "localforage";
import DemoPage from "@/use_cases/demo/DemoPage.vue";
import DemoIntroductionPage from "@/use_cases/demo/DemoIntroductionPage.vue";
import DemoSuccessfullyCompletedPage from "@/use_cases/demo_successfully_completed/DemoSuccessfullyCompletedPage.vue";
import RegisterAsAStudentPage from "@/use_cases/register_as_a_student/RegisterAsAStudentPage.vue";
import SelectMajorPage from "@/use_cases/select_major/SelectMajorPage.vue";
import EnterPage from "@/use_cases/login_sign_up/EnterPage.vue";
import LoginSignUpPage from "@/use_cases/login_sign_up/LoginSignUpPage.vue";
import LoginPage from "@/use_cases/login_sign_up/LoginPage.vue";
import SignUpPage from "@/use_cases/login_sign_up/SignUpPage.vue";
import RegisteredUserPage from "@/use_cases/RegisteredUserPage.vue";
import UnregisteredUserPage from "@/use_cases/UnregisteredUserPage.vue";
import ShowSubjectsPage from "@/use_cases/show_subjects/ShowSubjectsPage.vue";
import StudentPage from "@/use_cases/StudentPage.vue";
import ShowProductsPage from "@/use_cases/show_products/ShowProductsPage.vue";
import ShowSimulationExamsPage from "@/use_cases/show_simulation_exams/ShowSimulationExamsPage.vue";
import ShowCoursesPage from "@/use_cases/show_courses/ShowCoursesPage.vue";
import ExamPage from "@/use_cases/show_exam/ExamPage.vue";
import SimulationExamPage from "@/use_cases/show_exam/simulation_exam/SimulationExamPage.vue";
import ProfilePage from "@/use_cases/profile/ProfilePage.vue";
// eslint-disable-next-line max-len
import AnswerSheetResultsSummaryPage from "@/use_cases/show_answer_sheet_results_summary/AnswerSheetResultsSummaryPage.vue";
// eslint-disable-next-line max-len
import AnswerSheetResultsBySubjectPage from "@/use_cases/show_answer_sheet_results_by_subject/AnswerSheetResultsBySubjectPage.vue";
import ExplanationPage from "@/use_cases/show_explanation/ExplanationPage.vue";
import AddCoursePage from "@/use_cases/show_courses/AddCoursePage.vue";
import SocialAuth from "@/use_cases/login_sign_up/SocialAuth.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      beforeEnter: async (to, from, next) => {
        const jwt = await localForage.getItem("JWT");
        if (jwt === null) {
          next({ name: "UnregisteredUser" });
        } else {
          next({ name: "RegisteredUser" });
        }
      },
    },
    {
      path: "/uu",
      name: "UnregisteredUser",
      component: UnregisteredUserPage,
      beforeEnter: async (to, from, next) => {
        const jwt = await localForage.getItem("JWT");

        if (jwt) {
          next({ name: "RegisteredUser" });
        } else {
          next();
        }
      },
      redirect: { name: "LoginSignUpPage" },
      children: [
        {
          path: "ingresar",
          component: EnterPage,
          children: [
            {
              name: "LoginSignUpPage",
              path: "",
              component: LoginSignUpPage,
            },
            {
              name: "LoginPage",
              path: "iniciar-sesion",
              component: LoginPage,
            },
            {
              name: "SignUpPage",
              path: "registrarse",
              component: SignUpPage,
            },
            {
              path: "social-auth",
              name: "SocialAuth",
              component: SocialAuth,
              children: [
                {
                  name: "google",
                  path: "google",
                },
                {
                  name: "facebook",
                  path: "facebook",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/u",
      name: "RegisteredUser",
      component: RegisteredUserPage,
      beforeEnter: async (to, from, next) => {
        const isStudentRegistrationPending = await localForage.getItem("isStudentRegistrationPending");
        const jwt = await localForage.getItem("JWT");
        if (jwt === null) {
          next({ name: "UnregisteredUser" });
        } else if (isStudentRegistrationPending && to.name === "RegisteredUser") {
          next({ name: "DemoPage" });
        } else if (!isStudentRegistrationPending) {
          next({ name: "Student" });
        } else {
          next();
        }
      },
      children: [
        {
          path: "demo",
          name: "DemoPage",
          component: DemoPage,
          beforeEnter: async (to, from, next) => {
            const isStudentRegistrationPending = await localForage.getItem("isStudentRegistrationPending");
            if (isStudentRegistrationPending === false) {
              next({ name: "SelectMajorPage" });
            }
            next();
          },
        },
        {
          path: "demo-inicio",
          name: "DemoIntroductionPage",
          component: DemoIntroductionPage,
        },
        {
          path: "demo-completado",
          name: "DemoSuccessfullyCompletedPage",
          component: DemoSuccessfullyCompletedPage,
        },
        {
          path: "seleccionar-carrera",
          name: "SelectMajorPage",
          component: SelectMajorPage,
        },
        {
          path: "registro-del-estudiante/carrera-seleccionada/:desiredMajorId",
          name: "RegisterAsAStudentPage",
          component: RegisterAsAStudentPage,
          props: true,
        },
      ],
    },
    {
      path: "/s",
      name: "Student",
      component: StudentPage,
      beforeEnter: async (to, from, next) => {
        const isStudentRegistrationPending = await localForage.getItem("isStudentRegistrationPending");
        const jwt = await localForage.getItem("JWT");
        if (jwt === null) {
          next({ name: "UnregisteredUser" });
        } else if (isStudentRegistrationPending) {
          next({ name: "DemoPage" });
        } else {
          next();
        }
      },
      children: [
        {
          path: "curso/:courseProgressId/materias",
          name: "ShowSubjectsPage",
          component: ShowSubjectsPage,
          props: true,
        },
        {
          path: "curso/:courseProgressId/examenes-de-simulacion",
          name: "ShowSimulationExamsPage",
          component: ShowSimulationExamsPage,
          props: (route) => ({ courseProgressId: route.params.courseProgressId }),
        },
        {
          path: "hoja-de-respuestas/:answerSheetId/item/:answerSheetItemId",
          name: "ExamPage",
          component: ExamPage,
          props: (route) => ({
            answerSheetId: route.params.answerSheetId,
            answerSheetItemId: route.params.answerSheetItemId,
          }),
        },
        {
          path: "pregunta-del-dia/:answerSheetId/item/:answerSheetItemId",
          name: "DailyExamPage",
          component: ExamPage,
          props: (route) => ({
            answerSheetId: route.params.answerSheetId,
            answerSheetItemId: route.params.answerSheetItemId,
          }),
        },
        {
          path: "examen/:simulationExamId/hoja-de-respuestas/:answerSheetId/item/:answerSheetItemId",
          name: "SimulationExamPage",
          component: SimulationExamPage,
          props: (route) => ({
            simulationExamId: route.params.simulationExamId,
            answerSheetId: route.params.answerSheetId,
            answerSheetItemId: route.params.answerSheetItemId,
          }),
        },
        {
          path: "hoja-de-respuestas/:answerSheetId/resumen-de-resultados",
          name: "AnswerSheetResultsSummaryPage",
          component: AnswerSheetResultsSummaryPage,
          props: (route) => ({
            answerSheetId: route.params.answerSheetId,
          }),
        },
        {
          path: "hoja-de-respuestas/:answerSheetId/materia/:subjectId",
          name: "AnswerSheetResultsBySubjectPage",
          component: AnswerSheetResultsBySubjectPage,
          props: (route) => ({
            answerSheetId: route.params.answerSheetId,
            subjectId: route.params.subjectId,
          }),
        },
        {
          path: "hoja-de-respuestas/:answerSheetId/item/:answerSheetItemId/explicacion",
          name: "ExplanationPage",
          component: ExplanationPage,
          props: (route) => ({
            answerSheetId: route.params.answerSheetId,
            answerSheetItemId: route.params.answerSheetItemId,
          }),
        },
        {
          path: "tienda/",
          name: "ShowProductsPage",
          component: ShowProductsPage,
        },
        {
          path: "curso/:courseProgressId/:majorProgressId/mis-cursos",
          name: "ShowCoursesPage",
          component: ShowCoursesPage,
          props: (route) => ({
            courseProgressId: route.params.courseProgressId,
            majorProgressId: route.params.majorProgressId,
          }),
        },
        {
          path: "curso/:courseProgressId/agregar-curso/",
          name: "AddCoursePage",
          component: AddCoursePage,
          props: (route) => ({ courseProgressId: route.params.courseProgressId }),
        },
        {
          path: "perfil/",
          name: "ProfilePage",
          component: ProfilePage,
        },
      ],
    },
  ],
});
