<template>
  <div class="show-courses">
    <ContentSidebarLayout>
      <div slot="main-content">
        <div class="courses-container">
          <div class="container-add-course">
            <p class="course-text-my-courses">Mis Cursos</p>
            <router-link :to="{ name: 'AddCoursePage', params: { courseProgressId } }">
              <div data-test-id="show-add-courses-page">
                <AddCourseImage class="course-add_button" />
              </div>
            </router-link>
          </div>
          <div class="container-active" data-test-id="coursesActive">
            <div
              v-for="course in activeCourses"
              :key="course.major_id"
              :data-test-id="`course-active-${course.id_course_progress}`"
              class="course-active"
            >
              <img :src="course.image_url" alt="Escuela del Curso Activo" />
              <div class="course-info">
                <p class="course-name">{{ course.name }}</p>
                <p class="course-major">{{ course.major_name }}</p>
              </div>
            </div>
          </div>
          <div class="container-inactive" data-test-id="coursesInactive">
            <div
              v-for="course in inactiveCourses"
              :key="course.major_id"
              :data-test-id="`course-inactive-${course.id_course_progress}`"
              class="course-inactive"
            >
              <img :src="course.image_url" alt="Escuela del Curso Inactivo" />
              <div class="course-info">
                <p class="course-name">{{ course.name }}</p>
                <p class="course-major">{{ course.major_name }}</p>
                <router-link :to="{ name: 'Home', params: { localCourseProgressId } }">
                  <BaseButton
                    style-modifier="activate-course"
                    :data-test-id="`activate-course-button-${course.id_course_progress}`"
                    @click="onClickActiveCourse(course.id_course_progress, course.major_id)"
                    >Activar ></BaseButton
                  >
                </router-link>
              </div>
            </div>
          </div>
          <div class="course-btn-add">
            <router-link :to="{ name: 'AddCoursePage', params: { courseProgressId } }">
              <BaseButton style-modifier="secondary" data-test-id="accept-button">Añadir Nuevo Curso</BaseButton>
            </router-link>
          </div>
        </div>
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import AddCourseImage from "@/assets/add-course.svg?inline";
import ShowProducts from "@/use_cases/show_courses/ShowCourses";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import localForage from "localforage";

export default {
  name: "ShowCoursesPage",
  components: {
    ContentSidebarLayout,
    BaseButton,
    AddCourseImage,
  },
  props: {
    courseProgressId: {
      type: String,
      required: true,
    },
    majorProgressId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      courses: [],
      isLoading: false,
      activeCourseProgressId: "",
      localCourseProgressId: "",
    };
  },
  computed: {
    activeCourses() {
      return this.courses.filter((course) => course.major_id === this.majorProgressId);
    },
    inactiveCourses() {
      return this.courses.filter((course) => course.major_id !== this.majorProgressId);
    },
  },
  async mounted() {
    await this.getCourses();
    this.$nextTick(() => {
      this.$el.style.display = "block";
    });
    this.$emit("load-finished");
  },
  methods: {
    async getCourses() {
      try {
        this.isLoading = true;
        this.courses = await ShowProducts.getCourses();
        this.activeCourseProgressId = this.courseProgressId;
      } catch (err) {
        this.execActionResponse(err.response.status);
      } finally {
        this.isLoading = false;
      }
    },
    async onClickActiveCourse(CourseProgressId, MajorId) {
      await localForage.setItem("activeCourseProgressId", CourseProgressId);
      await localForage.setItem("majorId", MajorId);
      this.localCourseProgressId = await localForage.getItem("activeCourseProgressId");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_resolutions.scss";
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.show-courses {
  display: none;
}

.courses-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20%;

  @include laptop {
    padding-bottom: 5%;
  }
}

.course-major {
  @extend %font-body-3;
}

.course-btn-add {
  display: none;

  @include laptop {
    display: block;
    margin-top: 2em;
    width: 60%;
  }
}

.container-add-course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8em;
}

.course-text-my-courses {
  color: $ipn-color;

  @extend %font-title-1;

  @include laptop {
    padding-left: 150px;
  }
}

.course-info {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.course-add_button {
  cursor: pointer;

  @include laptop {
    display: none;
  }
}

.container-active {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2em;
  width: 90%;

  @include laptop {
    margin-top: -1em;
  }
}

.container-inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;

  @include laptop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
  }
}

.course-active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border: solid #fbb03b 3px;
  border-radius: 13px;
  width: 18em;
  margin-top: -3em;
  margin-bottom: 1em;

  img {
    max-width: 50%;
    min-width: 40%;
  }

  @include laptop {
    margin-top: 0;
    width: auto;
    padding: 0 25%;

    img {
      padding: 5% 0;
    }
  }

  .course-name {
    @extend %font-title-2;
  }
}

.course-inactive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border: solid #4d4d4d 1.5px;
  border-radius: 13px;
  background-color: #e5e5e5;
  width: 18em;
  margin-bottom: 1em;

  img {
    max-width: 50%;
    min-width: 40%;
  }

  @include laptop {
    padding: 0 4%;
    width: auto;
    height: 100%;
    margin-bottom: -0.5em;
    justify-self: center;

    img {
      padding: 10% 0;
      width: 40%;
    }
  }

  .course-name {
    @extend %font-subtitle-2;
  }
}
</style>
