<template>
  <div class="getProfile">
    <ContentSidebarLayout>
      <div slot="main-content" class="profile__desktop">
        <v-btn icon class="profile__button-return" data-test-id="button-return" @click="returnHome">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        <div class="profile__account_container">
          <div class="profile__account">
            <v-icon class="profile__icon" color="white">mdi-account</v-icon>
          </div>
          <div>
            <div class="profile__account-name">{{ profile.fullName }}</div>
            <div class="profile__account-email">{{ profile.email }}</div>
            <div :class="premiumClass">{{ checkPremium() }}</div>
          </div>
        </div>
        <BaseButton
          style-modifier="secondary"
          class="profile__button-premium"
          data-test-id="button-premium"
          @click="returnShop"
        >
          VOLVERME PREMIUM
        </BaseButton>
        <div class="profile__item">Políticas de privacidad</div>
        <div class="profile__policy-container">
          <a :href="privacyPolicy" class="profile__link" data-test-id="privacy-policy">Ver Políticas de privacidad</a>
          <div class="profile__link" data-test-id="logOut" @click="logOut">Cerrar sesión</div>
        </div>
      </div>

      <div slot="sidebar">
        <ProfileCard @return-shop="returnShop"></ProfileCard>
      </div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
import localForage from "localforage";
import BaseButton from "@/components/base_button/BaseButton.vue";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import GetProfile from "@/use_cases/profile/GetProfile";
import ProfileCard from "@/use_cases/profile/ProfileCard.vue";

export default {
  name: "ProfilePage",
  components: { ProfileCard, ContentSidebarLayout, BaseButton },
  data() {
    return {
      privacyPolicy: "https://www.ingresapp.xyz/aviso-de-privacidad.html",
      profile: {},
      premiumClass: "profile__account-no-premium",
    };
  },
  async mounted() {
    this.profile = await GetProfile.getProfile();
    this.$emit("load-finished");
  },
  methods: {
    async logOut() {
      await localForage.clear();
      await this.$router.push({ name: "Home" });
    },
    checkPremium() {
      if (this.profile.package) {
        this.premiumClass = "profile__account-premium";
        return this.profile.package.premium_type;
      }
      return "Aún no eres premium";
    },
    async returnHome() {
      await this.$router.push({ name: "Home" });
    },
    async returnShop() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.getProfile {
  @extend %font-body-1;

  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: initial;
  padding: 1em;

  @include laptop {
    padding: 0;
  }
}

.profile__desktop {
  @include laptop {
    padding: 0 6em;
  }
}

/** @define profile */

.profile__icon {
  font-size: 3em;

  @include laptop {
    font-size: 4em;
  }
}

.profile__button-return {
  padding: 0 0 1em 0.5em;
  width: 0;
}

.profile__button-premium {
  margin-bottom: 1em;

  @include laptop {
    display: none;
  }
}

.profile__account_container {
  display: flex;
  gap: 1.5em;
  margin-bottom: 1em;
  align-items: center;
}

.profile__account {
  background: $primary-color;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @include laptop {
    width: 5.5em;
    height: 5.5em;
  }
}

.profile__account-name {
  @extend %font-subtitle-1;
}

.profile__account-premium {
  @extend %font-body-2;

  color: $secondary-color;
}

.profile__account-no-premium {
  @extend %font-body-2;

  color: $error-color;
}

.profile__item {
  font-weight: bolder;
}

.profile__policy-container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.profile__link {
  @extend %font-body-2;

  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}
</style>
