<template>
  <v-snackbar v-model="snackbarVisible" :timeout="time" :color="backgroundColor" bottom center data-test-id="snackbar">
    <div :style="{ color: color }" class="main-content__snackbar-text">{{ text }}</div>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  props: {
    time: {
      type: Number,
      required: false,
      default: 3000,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "white",
    },
    color: {
      type: String,
      required: false,
      default: "#4CAF50",
    },
    text: {
      type: String,
      required: false,
      default: "GRACIAS POR TUS COMENTARIOS",
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    snackbarVisible: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit("update:isSnackbarVisible", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

::v-deep .v-snack__wrapper {
  margin: 0 0 4.5em;
  width: 25%;
  border: 2.5px solid v-bind("color") !important;
  border-radius: 8px;

  @include laptop {
    margin: 0 0 0.75em;
  }
}

.main-content__snackbar-text {
  font-weight: bold;
  text-align: center;
}
</style>
