<template>
  <v-app-bar app color="primary" fixed class="title-bar">
    <v-toolbar-title :class="{ 'title-bar__logo--centered': !isQuickMenuVisible }">
      <router-link v-if="isQuickMenuVisible" :to="{ name: 'Home' }"><LogoText /></router-link>
      <LogoText v-else />
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div v-if="isQuickMenuVisible" class="title-bar__menu">
      <v-btn outlined class="title-bar__icon" data-test-id="show-subjects-button" @click="onClickPractice">
        <v-icon large>$vuetify.icons.graduationCap</v-icon>
        <span>Practica</span>
      </v-btn>

      <v-btn
        outlined
        class="title-bar__icon"
        data-test-id="show-simulation-exams-button"
        @click="onClickSimulationExams"
      >
        <v-img
          max-width="28"
          max-height="28"
          class="mt-1 mb-1"
          src="../../assets/simulation-exams.png"
          alt="simulation exam"
        ></v-img>
        <span>Examenes</span>
      </v-btn>

      <v-btn outlined class="title-bar__icon" data-test-id="show-products-page-button" @click="onClickStore">
        <v-icon class="mt-1 mb-1">$vuetify.icons.store</v-icon>
        <span>Tienda</span>
      </v-btn>

      <v-btn outlined class="title-bar__icon" data-test-id="show-my-courses-button" @click="onClickCourses">
        <v-icon class="mt-1 mb-1">$vuetify.icons.course</v-icon>
        <span> Cursos </span>
      </v-btn>
    </div>

    <StudentCoinsView v-if="!isSimulationExamMenuIconVisible && isQuickMenuVisible" ref="coins" />

    <v-btn v-if="isSimulationExamMenuIconVisible" icon @click="emitExamImageClick">
      <v-icon class="mt-1 mb-1">$vuetify.icons.exam</v-icon>
    </v-btn>
    <v-btn icon data-test-id="profile-button" @click="onClickProfile">
      <v-icon color="white" size="35">mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// eslint-disable-next-line import/extensions
import localForage from "localforage";
import LogoText from "@/components/logo_text/LogoText.vue";
import StudentCoinsView from "@/use_cases/show_student_coins/StudentCoinsView.vue";

export default {
  name: "TitleBarNew",
  components: {
    StudentCoinsView,
    LogoText,
  },
  props: {
    isSimulationExamMenuIconVisible: {
      type: Boolean,
      required: true,
    },
    isQuickMenuVisible: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isMobileResolution: false,
      isLogOutMenuEnabled: false,
    };
  },
  watch: {
    isSimulationExamMenuIconVisible() {
      return this.isSimulationExamMenuIconVisible;
    },
    isQuickMenuVisible() {
      return this.isSimulationExamMenuIconVisible;
    },
  },
  methods: {
    emitExamImageClick() {
      this.$emit("exam-image-click");
    },
    async onClickProfile() {
      await this.$router.push({ name: "ProfilePage" });
    },
    async getCourseProgressId() {
      return localForage.getItem("activeCourseProgressId");
    },
    async onClickPractice() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSubjectsPage", params: { courseProgressId } });
    },
    async onClickSimulationExams() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSimulationExamsPage", params: { courseProgressId } });
    },
    async onClickStore() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
    async onClickCourses() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      const majorProgressId = await localForage.getItem("majorId");
      await this.$router.push({ name: "ShowCoursesPage", params: { courseProgressId, majorProgressId } });
    },
    async updateCoinsBalance() {
      this.$refs.coins.loadBalance();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define title-bar */
.title-bar {
  color: $white;
  padding: 0 5%;
}

.title-bar__logo--centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title-bar__icon::v-deep {
  height: 70px !important;
  width: 120px !important;
  border: none !important;

  .v-btn__content {
    height: 100%;
    flex-direction: column;
    color: white;
  }
}

.title-bar__menu {
  display: none;

  @media (min-width: $laptop-width) {
    display: block;
  }
}
</style>
