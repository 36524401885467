<template>
  <div>
    <ContentSidebarLayout>
      <div slot="main-content" class="register-as-a-student">
        <BaseContainer>
          <div class="register-as-a-student__img">
            <UserWithStarsImage />
          </div>

          <form class="register-as-a-student__form" @submit="registerAsAStudent">
            <div class="form__name-input">
              <BaseInputText
                v-model.trim="name"
                :min-length="3"
                placeholder="Nombre"
                class="name-input__input"
                :required="true"
                data-test-id="input-name"
              />
            </div>

            <div class="form__last-name-input">
              <BaseInputText
                v-model.trim="lastName"
                :min-length="3"
                placeholder="Apellido"
                class="last-name-input__input"
                :required="true"
                data-test-id="input-last-name"
              />
            </div>

            <div class="form__referrer-code">
              <BaseButton
                class="referrer-code__show-referrer-code-input-btn"
                style-modifier="text"
                data-test-id="button-show-referrer"
                @click.stop.prevent="isInputReferrerCodeVisible = true"
              >
                ¿Tienes un código de invitación?
              </BaseButton>

              <div v-show="isInputReferrerCodeVisible" class="referrer-code__referrer-code-input">
                <BaseInputText
                  v-model.trim="referrerCode"
                  placeholder="Código de invitación"
                  data-test-id="input-referrer-code"
                />
              </div>
            </div>

            <BaseButton
              class="form__finish-btn"
              style-modifier="secondary"
              :is-disabled="isFinishButtonDisabled"
              data-test-id="button-finish"
            >
              Finalizar
            </BaseButton>
          </form>
        </BaseContainer>
      </div>

      <div slot="sidebar"></div>
    </ContentSidebarLayout>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import UserWithStarsImage from "@/assets/user-with-stars.svg?inline";
import BaseInputText from "@/components/base_input_text/BaseInputText.vue";
import BaseButton from "@/components/base_button/BaseButton.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import RegisterAsAStudent from "@/use_cases/register_as_a_student/RegisterAsAStudent";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import localForage from "localforage";
import facebookTracking from "@/facebookTracking";

export default {
  name: "RegisterAsAStudentPage",
  components: {
    BaseContainer,
    BaseInputText,
    BaseButton,
    UserWithStarsImage,
    ContentSidebarLayout,
  },
  props: {
    desiredMajorId: {
      type: Number,
      required: true,
    },
    desiredMajor: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: "",
      lastName: "",
      referrerCode: "",
      isInputReferrerCodeVisible: false,
    };
  },

  computed: {
    isFinishButtonDisabled() {
      return this.name.length < 3 || this.lastName.length < 3;
    },
  },

  mounted() {
    this.$emit("load-finished");
  },

  methods: {
    async registerAsAStudent(e) {
      e.preventDefault();

      const response = await RegisterAsAStudent.registerAsAStudent({
        name: this.name,
        lastName: this.lastName,
        referrerCode: this.referrerCode,
        desiredMajorId: this.desiredMajorId,
      });
      await localForage.setItem("activeCourseProgressId", response.courseProgressId);

      this.$gtag.event("sign_up");
      facebookTracking.trackEvent({ eventName: "CompleteRegistration", params: {} });

      await this.$router.push({
        name: "ShowSubjectsPage",
        params: {
          courseProgressId: response.courseProgressId,
          desiredMajor: this.desiredMajor,
          userName: this.name,
        },
      });
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_fonts.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define register-as-a-student; */
.register-as-a-student {
  font-size: map-get($font-base, size);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.register-as-a-student__img {
  width: 100%;
  padding-bottom: 3em;
  padding-top: 4em;

  @include laptop {
    width: 40%;
    padding-top: 6em;
  }
}

.register-as-a-student__form {
  @extend %font-form;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

/** @define form; */
.form__name-input {
  width: 90%;

  @include laptop {
    width: 50%;
  }
}

.form__last-name-input {
  width: 90%;
  padding: 2em 0 2.5em;

  @include laptop {
    width: 50%;
  }
}

.form__referrer-code {
  width: 95%;
  padding-bottom: 1em;

  @include laptop {
    width: 50%;
  }
}

.form__finish-btn {
  width: 100%;
  padding-top: 1em;

  @include laptop {
    width: 70%;
  }
}

/** @define referrer-code; */
.referrer-code__referrer-code-input {
  width: 100%;
}

.referrer-code__show-referrer-code-input-btn {
  color: $ipn-color;
  width: 100%;
}
</style>
